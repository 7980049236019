import React from "react"
// import { Link } from "gatsby"
import styled from 'styled-components'

import Layout from "../components/layout"
// import Img from "gatsby-image/withIEPolyfill"
import Image from "../components/image"
import BgSection from "../components/bgimage"
import SimpleSlider from "../components/slick"
import Logo from "../components/icons/mv_logo"
import Button from "../components/button"
import Header from "../components/header"
import Widgets from "../components/widgets"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout type="home">
    <SEO title="酒場元木" />
    <Header type="home" />
    <div id="content">
      <section id="mv" className="cSection">
        {/* <Swiper></Swiper> */}
        <SimpleSlider>
          <figure className="bgSlide bgSlide0"></figure>
          <figure className="bgSlide bgSlide1"></figure>
          <figure className="bgSlide bgSlide2"></figure>
          <figure className="bgSlide bgSlide3"></figure>
          <figure className="bgSlide bgSlide4"></figure>
        </SimpleSlider>
        <p className="mvLogo"><Logo></Logo></p>

      </section>
      <section id="about" className="cSection">
        <div className="cTitleArea">
          <div className="cInnerArea">
            <h2 className="cTitle">
              <span className="ja">酒場について</span>
              <span className="en">About</span>
            </h2>
            <h3 className="cLiner">東京下町の酒場スタイルで、<br className="cSP"/>さまざまなお酒とお料理を。</h3>
            <p>酒場元木は、2017年3月に東京出身のケイコとヨイチが開業いたしました。<br />
            東京下町の酒場スタイルで、さまざまなお酒とお料理をお楽しみ頂けます。</p>
            <p>酒場というものは、お店とお客様たちが一体となり、お酒を楽しむ空間を一緒に作り上げる場所です。<br />
            安価な価格で日常的にご利用頂ける空間を目指しております。</p>
            <p>楽しい出来事、悲しい出来事、憤慨する出来事、全てこの酒場でストレスを吐き出し、<br className="cPC" />
            明日へと繋がる新しい一歩を踏み出してくださるよう、心より祈っております。</p>
          </div>
        </div>
        <div className="cInnerArea">
          <div className="cProfile">
            <figure className="cPC"><Image filename="prof_41@2x.jpg" alt="元木ヨイチ" /></figure>
            <h2 className="pTitle">
              <span className="ja">元木 ヨイチ</span>
              <span className="en">Yoichi Motoki</span>
            </h2>
            <div className="pTextArea">
            <figure className="cSP class41"><Image filename="prof_41@2x.jpg" alt="元木ヨイチ" /></figure>
              <p className="cLiner">プロフィール</p>
              <p className="pText">バブル期の銀座にてバーテンダーとしてスタート。<br/>
              銀座の老舗バー「セント・サワイ・オリオンズ」に入店し、世界的バーテンダー「澤井慶明」に師事。</p>
              <p className="pText">30歳のときに英国就労ビザを取得しスコットランドのアラン蒸溜所にてスチルマンとして勤務。<br className="cSP"/>
              スコットランド2年間でアラン蒸溜所に勤めながら、スプリングバンク蒸溜所、ザ・スコッチ・モルト・ウイスキー・ソサエティー本部にて勤務。</p>
              <p className="pText">帰国後は、スコッチウイスキーの商社「株式会社ウィスク・イー」に入社。テイスティング会講師やイベントディレクターとして勤務。<br/>
              ブリュードッグ・ジャパンに出向しシニアマネージャーとして勤務。</p>
              <p className="pText">京都蒸溜所を設立する仕事で京都に移住。<br/>
              季の美の開発に携わり、その後独立。</p>
              <p className="pText">洋酒業界に携わって30年以上になります。</p>
            </div>
          </div>
        </div>
      </section>
      <section id="menu" className="cSection">
        <StyledBgSection className="cTitleArea" tag="div" filename="bg_menu@2x.jpg" loading="eager">
          <div className="cInnerArea">
            <h2 className="cTitle">
              <span className="ja">メニュー</span>
              <span className="en">Menu</span>
            </h2>
            <h3 className="cLiner">お求めやすいホッとするものから、希少な価値を楽しむものまで<br className="cPC" />
            さまざまなお酒とお料理をお楽しみ頂けます。</h3>
          </div>
        </StyledBgSection>
        <div id="ball" className="cRecommends">
        <figure className="bg"><Image filename="bg_ball@2x.jpg" alt="" /></figure>
          <div className="cInnerArea">
            <h2 className="rTitle">
              <span className="catch">ヨイチ秘伝の</span>
              <span className="title">ボール</span>
            </h2>
            <p className="rTypography">Ball</p>
            <div className="rTextArea">
              <p>東京下町（墨田区）で生まれた元祖酎ハイの「ボール」は、強炭酸をボトルごと一気に注ぎ、そこにヨイチ秘伝ブレンド「酎ハイのタネ」をなみなみ注いで提供いたします。</p>
              <p>氷なしの表面張力まで注がれたボールは、あと味スッキリ、何杯でも飲めてしまいそうな危険な人気ドリンクです。</p>
            </div>
          </div>
        </div>
        <div id="gintonic" className="cRecommends">
          <figure className="bg"><Image filename="bg_gin@2x.jpg" alt="" /></figure>
          <div className="cInnerArea">
            <h2 className="rTitle">
              <span className="catch">人気No.1</span>
              <span className="title">季の美ジントニック</span>
            </h2>
            <p className="rTypography">Gin<br />Tonic&nbsp;</p>
            <div className="rTextArea">
              <p>人気のドリンクはもちろん「季の美ジントニック」です。</p>
              <p>京都蒸溜所設立と季の美の開発に関わったヨイチが、最も美味しい状態で提供いたします。</p>
              <p>また、本物の英国産トニックウォーター「フィーバーツリー」という最高の組み合わせで、「今まで飲んでいたジントニックって何だったの！？」と思えるほどのジントニックを体感してみてください。</p>
            </div>
          </div>
            <div className="cInnerArea">
          <div className="cProfile">
              <figure><Image filename="prof_kinobi@2x.jpg" alt="季の美" /></figure>
              <h2 className="pTitle">
                <span className="ja">季の美</span>
              </h2>
              <div className="pTextArea">
                <p className="pText">日本初、ジン専門の京都蒸溜所がつくるジャパニーズクラフトジン</p>
                <p className="pText">季の美 京都ドライジンは、お米からつくるライススピリッツとボタニカルに玉露や柚子、檜や山椒など日本ならではの素材を積極的に取り入れ、伏見の柔らかくきめ細やかな伏流水を使用。英国と京都の伝統を融合させたプレミアムクラフトジンです。</p>
                <p className="pText">ジュニパーベリーの効いたロンドンドライスタイルに「和」のエッセンスを加えた唯一無二の味わいをお楽しみいただけます。</p>
                <p className="btn1">
                  <a href="https://kyotodistillery.jp/products/kinobi/" target="_blank" rel="noreferrer">
                    <Button icon="blank">季の美 製品サイト</Button>
                  </a>
                </p>

              </div>
            </div>
          </div>
        </div>
        <div id="whiskies" className="cRecommends">
        <figure className="bg"><Image filename="bg_whiskies@2x.jpg" alt="" /></figure>
          <div className="cInnerArea">
            <h2 className="rTitle">
              <span className="catch">お気に入りを見つけたい</span>
              <span className="title">ウイスキー</span>
            </h2>
            <p className="rTypography">Whiskies</p>
            <div className="rTextArea">
            <p>ラインナップのほとんどはスコッチウイスキーです。<br/>
            オールドボトルや稀少な限定ボトルを中心に数百本の中からお気に入りの1本を見つけて下さい。</p>
            <p>いちばん人気は「アラン10年」です。<br/>
            スコットランドのアラン蒸溜所に2年間勤務していたヨイチのおススメです。</p>
            </div>
          </div>
            <div className="cInnerArea">
          <div className="cProfile">
            <figure><Image filename="prof_arran@2x.jpg" alt="アラン" /></figure>
              <h2 className="pTitle">
                <span className="ja">アラン蒸溜所</span>
              </h2>
              <div className="pTextArea">
                <p className="pText">アラン蒸溜所は1995年にスコットランド・アラン島のロックランザ村に誕生した蒸溜所です。</p>
                <p className="pText">同蒸溜所は独立資本のため、ブレンド用の原酒づくりがメインではなく、シングルモルトとして飲むためのウイスキーをつくっている数少ない蒸溜所です。</p>
                <p className="pText">昨今世界各地で産声を上げているクラフト蒸溜所のパイオニアとして知られており、小型の4基（2対）の蒸溜器で丁寧に蒸溜を行っています。</p>
                <p className="btn1">
                <a href="https://whisk-e.co.jp/product_types/arran/" target="_blank" rel="noreferrer">
                  <Button icon="blank">アラン蒸溜所について</Button>
                </a>
                </p>

              </div>
            </div>
          </div>
        </div>
        <div id="cocktail" className="cRecommends">
        <figure className="bg"><Image filename="bg_cocktail@2x.jpg" alt="" /></figure>
          <div className="cInnerArea">
            <h2 className="rTitle">
              <span className="catch">フードとの相性も楽しい</span>
              <span className="title">カクテル</span>
            </h2>
            <p className="rTypography">Cocktail</p>
            <div className="rTextArea">
              <div className="rMovie">
                <iframe title="aaa" width="320" height="568" src="//www.youtube.com/embed/NKjP8nIZDHg?autoplay=1&playsinline=1&mute=1&loop=1&playlist=NKjP8nIZDHg" frameBorder="0" allowFullScreen></iframe>
              </div>
              <div className="rText">
                <p>ショートカクテルのいちばん人気は「季の美マティーニ」です。</p>
                <p>超ドライなレシピですが、丁寧なロングステアによる円みがあり、季の美の絶妙なボタニカルを感じながらスルスル飲めてしまう不思議なマティーニです。当店自慢の小浜直送の「サバの醤油干し」との相性抜群です。</p>
                <p>サバとマティーニの組み合わせで、当店では「サバティーニ」と呼ばれ、多くのお客様を虜にしております。</p>
              </div>
            </div>
          </div>
        </div>
        <div id="food" className="cRecommends">
        <figure className="bg"><Image filename="bg_food@2x.jpg" alt="" /></figure>
          <div className="cInnerArea">
            <h2 className="rTitle">
              <span className="catch">あっさりからガッツリまで</span>
              <span className="title">フード</span>
            </h2>
            <p className="rTypography">Food</p>
            <div className="rTextArea">
            <p>お料理もぜひお楽しみください。</p>
            <p>お酒にあうポテサラや鶏天、福井県小浜から仕入れてくる魚の醤油干しが人気です。酒のアテになるツマミも取り揃えております。</p>
            <p>また、季節や仕入れ状況に合わせ、特別なメニューを提供させて頂くこともございます。</p>
          </div>
        </div>
        </div>
      </section>
      {/* <section id="sakaya" className="cSection">
        <StyledBgSection className="cTitleArea" tag="div" filename="bg_sakaya@2x.jpg" loading="eager">
          <div className="cInnerArea">
            <h2 className="cTitle">
              <span className="ja">酒屋元木</span>
              <span className="en">Sakaya</span>
            </h2>
            <h3 className="cLiner">酒屋元木は2019年に酒販免許を取得いたしました。 <br/>
            “酒場”元木で日頃お飲み頂いている美味しいお酒を、お持ち帰り可能な酒販業務を行っております。</h3>
            <p className="btn1"><Link to="/sakaya/">
              <Button>酒屋元木 詳細ページ</Button>
            </Link></p>
          </div>
        </StyledBgSection>
      </section> */}
      <Widgets type="sakaba" />
    </div>
  </Layout>
)

export default IndexPage

const StyledBgSection = styled(BgSection)`
  &::before,
  &::after {
    filter: brightness(
      ${({ isDarken }) => {
        return '80%'
      }}
    );
  }
`