import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Link as SLink } from 'react-scroll';

import Logo from "../components/icons/header_logo"
// import styled from "styled-components"
// import "./_mixin.scss"

const HLogo = ({ type,navbarOpen,setNavbarOpen,className,id='' }) => (
	<p id={id} className={`logo ${className&&className}`}>
		{type === "home"?
			<SLink role="button" to="mv" spy={true} smooth={true} duration={800}
			onClick={() => setNavbarOpen(!navbarOpen)}
			onKeyDown={() => setNavbarOpen(!navbarOpen)}
			>
			<Logo/>
			</SLink>
			:
			<Link to="/"><Logo/></Link>
		}
	</p>
)
const HNavi = ({navbarOpen,setNavbarOpen}) => (
	<div className="naviWrapper">
	<ul className="gnavi jsStagger">
		<li className="navi1" data-nav="about"><SLink role="button" activeClass="active" to="about" spy={true} smooth={true} duration={800}
		onClick={() => setNavbarOpen(!navbarOpen)}
		onKeyDown={() => setNavbarOpen(!navbarOpen)}
		>About</SLink></li>
		<li className="navi1" data-nav="menu"><SLink role="button" activeClass="active" to="menu" spy={true} smooth={true} duration={800}
		onClick={() => setNavbarOpen(!navbarOpen)}
		onKeyDown={() => setNavbarOpen(!navbarOpen)}
		>Menu</SLink></li>
		{/* <li className="navi1" data-nav="sakaya"><SLink role="button" activeClass="active" to="sakaya" spy={true} smooth={true} duration={800}
		onClick={() => setNavbarOpen(!navbarOpen)}
		onKeyDown={() => setNavbarOpen(!navbarOpen)}
		>Sakaya</SLink></li> */}
	</ul>
	<ul className="snavi jsStagger">
		<li className="navi1" data-nav="info"><SLink role="button" activeClass="active" to="info" spy={true} smooth={true} duration={800}
		onClick={() => setNavbarOpen(!navbarOpen)}
		onKeyDown={() => setNavbarOpen(!navbarOpen)}
		>ACCESS</SLink></li>
	</ul>
</div>
)
const Header = ({ type }) => {
	const [navbarOpen, setNavbarOpen] = useState(false)
	return(
  <>
		{type === "home"?
			<div id="menuBtn" role="button" tabIndex={0}
				className={navbarOpen?'opened':null}
				onClick={() => setNavbarOpen(!navbarOpen)}
				onKeyDown={() => setNavbarOpen(!navbarOpen)}
				aria-label="Open Menu Panel"
			>
				<span>
					<span className="top">OpenMenuPanel</span>
					<span className="middle"></span>
					<span className="bottom"></span>
				</span>
			</div>
			:
			<HLogo id="spLogoWrapper" className="cSP splogo" type={type} />
		}
		{/* <header id="menu" onTouchStart=""> */}
		<header id="navi"
		className={navbarOpen?'opened':null}>
			<div className="scrollBox">
				<nav className="inner1">
					<HLogo type={type} navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
					{type === "home"&& <HNavi navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} /> }
				</nav>
			</div>
		</header>

  </>
	)
}


Header.propTypes = {
  type: PropTypes.string,
}

Header.defaultProps = {
  type: ``,
}

export default Header

// const ToggleArea = styled.div`
// 	.top { transform: ${props => (props.open ? "rotate(-45deg) translateY(0px)" : "translateY(-10px)")};}
// 	.middle { transform: ${props => (props.open ? "rotate(-45deg) translateY(0px)" : "translateX(0px)")};}
// 	.bottom { transform: ${props => (props.open ? "rotate(-45deg) translateY(0px)" : "translateY(10px)")};}
// 	/* @media #{$sp} { */
// 	@media (max-width: 768px) {
// 		display: block;
// 	}
// 	@media #{$pc} {
// 		display: none;
// 	}

// `
